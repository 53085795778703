'use client';

import { useEffect, useState } from 'react';
import { apiMenuItems } from '@/components/navigation';
import { usePathname, useSearchParams } from 'next/navigation';
import { Icon, Link } from '@/components/utilities';

function MenuLink({ href, icon, iconColor, text, active }) {
  return (
    <Link
      href={href}
      className={`flex items-center  ${
        active ? 'text-1' : 'text-3 hover:text-1'
      }`}
      title={text}>
      <Icon
        icon={icon}
        className={`w-5 h-5 xl:w-6 xl:h-6 mr-3 xl:mr-4 ${iconColor}`}
      />
      <span className="hidden lg:block max-w-[15rem] 2xl:max-w-[20rem] whitespace-nowrap text-ellipsis overflow-hidden text-sm xl:text-base leading-6 font-code">
        {text}
      </span>
    </Link>
  );
}

function PageLink({ href, icon, iconColor, text, active }) {
  return (
    <Link
      href={href}
      className={`group flex items-center pl-1.5  ${
        active ? 'text-1' : 'text-3 hover:text-1'
      }`}
      title={text}>
      <Icon
        icon={icon}
        className={`w-4 h-4 xl:w-5 xl:h-5 mr-2.5 xl:mr-3.5 ${iconColor}`}
      />
      <span className="hidden lg:block max-w-[15rem] 2xl:max-w-[20rem] whitespace-nowrap text-ellipsis overflow-hidden text-xs xl:text-sm leading-6 font-code">
        {text}
      </span>
    </Link>
  );
}

function SectionLink({ id, title, active }) {
  return (
    <a
      href={`#${id}`}
      className={`block py-1.5 pl-1.5 text-xs leading-4 ${
        active ? 'text-1' : 'text-3 hover:text-1'
      } font-code`}>
      {title}
    </a>
  );
}

export default function ApiMenu() {
  const pathname = usePathname();
  const parts = pathname.split('/');
  let lastPath = parts[parts.length - 1];
  if (pathname === '/docs/api') {
    lastPath = '';
  }

  const params = useSearchParams();
  const [hash, setHash] = useState('');

  useEffect(() => {
    if (window?.location?.hash) {
      setHash(window.location.hash.slice(1));
    }
  }, [pathname, params]);

  useEffect(() => {
    let sections = [];
    apiMenuItems.forEach(item => {
      item.pages?.forEach(page => {
        if (
          (lastPath === page.slug ||
            (page.slug === '' && pathname === '/docs/api')) &&
          page.sections
        ) {
          sections = page.sections;
        }
      });
    });

    const handleScroll = event => {
      let currentSection = '';
      sections.forEach(section => {
        const el = document.getElementById(section.id);
        const sectionTop = el?.getBoundingClientRect().top;
        if (sectionTop >= 0 && sectionTop <= window.innerHeight / 3) {
          currentSection = section.id;
        }
      });
      if (currentSection && currentSection !== hash) {
        setHash(currentSection);
        history.replaceState(null, '', `#${currentSection}`);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [pathname]);

  return (
    // TODO: Use details/summary to collapse/expand sections
    <ul className={`flex flex-col items-stretch justify-start pt-8 pb-16`}>
      {apiMenuItems?.map(item => {
        const active = Boolean(
          lastPath === item.slug ||
            (item.slug === '' && pathname === '/docs/api'),
        );
        const activePage = Boolean(
          item.pages?.find(
            page =>
              page.slug === lastPath ||
              (page.slug === '' && pathname === '/docs/api'),
          ),
        );
        return (
          <div key={item.slug} className="py-2">
            <li>
              <MenuLink
                href={`/docs/api/${item.slug}`}
                icon={item.icon}
                iconColor={item.iconColor}
                text={item.title}
                active={active || activePage}
              />
            </li>
            {(active || activePage) && item.pages ? (
              <ul className={`py-2 xl:py-3`}>
                {item.pages.map((page, index) => {
                  const active = Boolean(
                    lastPath === page.slug ||
                      (page.slug === '' && pathname === '/docs/api'),
                  );
                  return (
                    <div key={index} className="py-1 xl:py-1.5">
                      <li>
                        <PageLink
                          href={`/docs/api/${page.slug}`}
                          icon={page.icon}
                          iconColor={
                            active
                              ? page.iconColor
                              : `text-5 group-hover:${page.iconColor}`
                          }
                          text={page.title}
                          active={active}
                        />
                      </li>
                      {active && page.sections ? (
                        <ul className={`py-1.5`}>
                          {page.sections.map((section, index) => (
                            <li key={index} onClick={() => setHash(section.id)}>
                              <SectionLink
                                id={section.id}
                                title={section.title}
                                active={section.id === hash}
                              />
                            </li>
                          ))}
                        </ul>
                      ) : null}
                    </div>
                  );
                })}
              </ul>
            ) : null}
          </div>
        );
      })}
    </ul>
  );
}
