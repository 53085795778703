'use client';

import { getProjectMenuItems } from '@/components/navigation';
import { usePathname, useSearchParams } from 'next/navigation';
import { Icon, Link } from '@/components/utilities';

function ProjectLink({ href, icon, iconColor, text, active }) {
  return (
    <Link
      href={href}
      className={`flex items-center  ${
        active ? 'text-1' : 'text-3 hover:text-1'
      }`}
      title={text}>
      <Icon
        icon={icon}
        className={`w-5 h-5 xl:w-6 xl:h-6 mr-3 xl:mr-4 ${iconColor}`}
      />
      <span className="hidden lg:block max-w-[15rem] 2xl:max-w-[20rem] whitespace-nowrap text-ellipsis overflow-hidden text-sm xl:text-base leading-6 font-code">
        {text}
      </span>
    </Link>
  );
}

function PageLink({ href, icon, iconColor, text, active }) {
  return (
    <Link
      href={href}
      className={`group flex items-center pl-1.5  ${
        active ? 'text-1' : 'text-3 hover:text-1'
      }`}
      title={text}>
      <Icon
        icon={icon}
        className={`w-4 h-4 xl:w-5 xl:h-5 mr-2.5 xl:mr-3.5 ${iconColor}`}
      />
      <span className="hidden lg:block max-w-[15rem] 2xl:max-w-[20rem] whitespace-nowrap text-ellipsis overflow-hidden text-xs xl:text-sm leading-6 font-code">
        {text}
      </span>
    </Link>
  );
}

export default function ProjectMenu({ user, project }) {
  const pathname = usePathname();
  const params = useSearchParams();
  let paramsString = params.toString();

  const items = getProjectMenuItems({ user, project });
  const secondaryItems = [];
  if (pathname !== `/${project.alias}`) {
    secondaryItems.push({
      href: `/${project.alias}`,
      text: 'Back to Project',
      icon: 'project',
    });
  }
  secondaryItems.push({
    href: `/orgs/${project.organisation.alias}`,
    text: 'Back to Org',
    icon: 'organisation',
  });
  secondaryItems.push({
    href: '/orgs',
    text: 'Dashboard',
    icon: 'dashboard',
  });
  return (
    <ul className={`flex flex-col items-stretch justify-start py-12`}>
      <li className="pb-12">
        {project ? (
          <li className="pb-4">
            <Link
              href={`/${project.alias}`}
              className={`flex items-center ${
                pathname === `/${project.alias}`
                  ? 'text-1'
                  : 'text-2 hover:text-1'
              }`}>
              <span className="hidden lg:block max-w-[15rem] 2xl:max-w-[20rem] whitespace-nowrap text-ellipsis overflow-hidden text-base leading-6 font-code">
                {project.name}
              </span>
            </Link>
          </li>
        ) : null}
        {items
          ?.filter(i => i.primary)
          .map(item => {
            const active = Boolean(pathname.includes(item.href));
            return (
              <div key={item.href} className="py-2">
                <li>
                  <ProjectLink
                    href={item.href}
                    icon={item.icon}
                    iconColor={item.iconColor || 'text-brand-purple'}
                    text={item.text}
                    active={active}
                  />
                </li>
                {active && item.pages ? (
                  <ul className={`py-2 xl:py-3`}>
                    {item.pages.map((page, index) => {
                      const activePage = Boolean(
                        active && paramsString.includes(page.params),
                      );
                      return (
                        <div key={index} className="py-1 xl:py-1.5">
                          <li>
                            <PageLink
                              href={page.href}
                              icon={page.icon}
                              iconColor={
                                activePage
                                  ? page.iconColor
                                  : `text-5 group-hover:${page.iconColor}`
                              }
                              text={page.text}
                              active={activePage}
                            />
                          </li>
                        </div>
                      );
                    })}
                  </ul>
                ) : null}
              </div>
            );
          })}
      </li>
      <ul>
        {secondaryItems.map(item => (
          <li key={item.href} className="py-2">
            <Link
              href={item.href}
              className={`flex items-center ${
                pathname === item.href ? 'text-1' : 'text-3 hover:text-1'
              }`}
              title={item.text}>
              <Icon
                icon={item.icon}
                className={`w-4 h-4 xl:w-5 xl:h-5 mr-4 xl:mr-5 text-brand-purple`}
              />
              <span className="hidden lg:block max-w-[15rem] 2xl:max-w-[20rem] whitespace-nowrap text-ellipsis overflow-hidden text-xs xl:text-sm leading-6 font-code">
                {item.text}
              </span>
            </Link>
          </li>
        ))}
      </ul>
    </ul>
  );
}
