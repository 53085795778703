export { default as NavigationLink } from './NavigationLink';
export { default as NavigationLogo } from './NavigationLogo';
export { default as MenuUserLinks } from './MenuUserLinks';
export { default as Breadcrumbs } from './Breadcrumbs';
export { default as PublicNavigation } from './PublicNavigation';
export { default as PublicMobileMenu } from './PublicMobileMenu';
export { default as FeaturesMenu } from './FeaturesMenu';
export { default as ApiMenu } from './ApiMenu';
export { default as OrgNavigation } from './OrgNavigation';
export { default as OrgMobileMenu } from './OrgMobileMenu';
export { default as OrgMenu } from './OrgMenu';
export { default as ProjectNavigation } from './ProjectNavigation';
export { default as ProjectMobileMenu } from './ProjectMobileMenu';
export { default as ProjectMenu } from './ProjectMenu';

export const marketingMenuItems = [
  {
    title: 'Upload',
    headline: 'Add secure uploads in minutes',
    lede: 'Ingest directly from your bucket or any public URL, POST files from your server, or create signed URLs for secure client-side uploads',
    body: 'Ittybit provides a simple API for uploading files directly from your server or for creating signed URLs (for secure client-side uploads). You can also ingest directly from your bucket or any public URL. In all cases you can add limit accepted filetypes or filesizes, add custom metadata, and set expiry dates to automatically clean-up files after a custom period.',
    bullets: [
      'Upload directly from your server',
      'Create signed URLs for secure client-side uploads',
      'Ingest directly from your bucket or any public URL',
      'Securely store your media in the cloud, encrypted-at-rest',
      'Built-in redundancy and distributed backups',
      'Limit accepted filetypes or filesizes',
      'Add custom metadata',
      'Set custom expiry dates',
    ],
    image: 'https://ittybit-app.ittybitcdn.com/med_xy7bf3lwgo3n/original',
    alt: 'ittybit mascot robot in front of a screenshot of the uploads screen',
    icon: 'upload',
    iconColor: 'text-brand-green',
    borderColor: 'border-brand-green',
    prefix: 'features',
    slug: `upload`,
    primary: true,
  },
  {
    title: 'Images',
    headline: 'Serve images at scale',
    lede: 'Deliver the perfect image for every device and browser with automatic format conversion, compression, and resizing',
    body: 'Ittybit provides a simple API for creating all the formats and sizes you need to support any device or browser. You can also save bandwidth, speed-up loading times, and eliminate buffering with industry-leading compression. Then enhance your images with AI-powered features like nsfw detection, object labeling, and text extraction.',
    image: 'https://ittybit-app.ittybitcdn.com/med_gorhhrv2shkd/original',
    alt: 'ittybit mascot robot in front of a screenshot of the images screen',
    icon: 'image',
    iconColor: 'text-brand-yellow',
    borderColor: 'border-brand-yellow',
    prefix: 'features',
    slug: `images`,
    primary: true,
  },
  {
    title: 'Video',
    headline: 'Streamline your video workflow',
    lede: 'Automatically create transcripts, subtitles, and thumbnails, and convert video formats for any device or browser',
    body: 'Ittybit provides a simple API for creating all the formats and sizes you need to support any device or browser. You can also save bandwidth, speed-up loading times, and eliminate buffering with industry-leading compression. Then enhance your videos with AI-powered features like nsfw detection, object labeling, and text extraction.',
    icon: 'video',
    iconColor: 'text-brand-blue',
    borderColor: 'border-brand-blue',
    prefix: 'features',
    slug: `video`,
    primary: true,
  },
  {
    title: 'Audio',
    headline: 'Enhance the conversation',
    lede: 'Automatically generate transcripts, detect silence, and remove noise from your audio files',
    body: 'Ittybit provides a simple API for creating all the formats and sizes you need to support any device or browser. You can also save bandwidth, speed-up loading times, and eliminate buffering with industry-leading compression. Then enhance your audio with AI-powered features like speech detection, silence removal, and sentiment analysis.',
    icon: 'audio',
    iconColor: 'text-brand-orange',
    borderColor: 'border-brand-orange',
    prefix: 'features',
    slug: `audio`,
    primary: true,
  },
  {
    title: 'Intelligence',
    headline: 'Intelligence',
    lede: 'Easily add AI-powered features to your app. Get transcripts, detect objects, extract text, flag NSFW content, and more',
    body: "Ittybit provides a single API for adding powerful AI-powered features to your app. You can get transcripts from audio and video, automatically generate subtitles for videos, label objects, extract text, detect NSFW content, detect faces and emotions, and much more. AI tools are evolving at an incredible pace, so we're constantly adding new features with the same simple API. You don't need to buy GPUs or become an AI expert to take advantage of the latest advances in your own apps.",
    bullets: [
      'Create transcripts from audio and video',
      'Automatic subtitles for videos',
      'Label objects in images and videos',
      'Extract text from images and videos',
      'Detect NSFW content in images and videos',
      'Detect faces and emotions in images and videos',
      'Video summarization (great for search)',
      'Image description (prompt generation)',
      'Image and video profiling (colors, formats, sizes, etc)',
      'Scene and shot detection in videos',
      'Motion tracking and stabilization in videos',
      'Clean up images (noise reduction, text removal, and object removal)',
      'Enhance images (super resolution)',
      'Remove background from images and videos (rotoscope)',
      'Enhance audio (noise reduction, volume normalization, and more)',
      'Detect and remove silence from audio',
    ],
    image: 'https://ittybit-app.ittybitcdn.com/med_91gu7liivehg/original',
    alt: 'ittybit mascot robot in front of a screenshot of the intelligence screen',
    beta: true,
    icon: 'intelligence',
    iconColor: 'text-brand-magenta',
    borderColor: 'border-brand-magenta',
    prefix: 'features',
    slug: `intelligence`,
    primary: true,
  },
  {
    title: 'Transform',
    headline: 'Convert, crop, and compress media',
    lede: 'Create all the formats and sizes you need to support any device or browser, and save up to 90% bandwidth with industry-leading compression, all without commisioning servers or worrying about scaling',
    bullets: [
      'Create all the formats and sizes you need to support any device or browser',
      'Crop images and videos',
      'Resize images and videos',
      'Rotate images and videos',
      'Trim video and audio files',
      'Blur and pixelate images and videos',
      'Edit image contrast, brightness, saturation, etc',
      'Add watermarks and logo overlays',
      'Add text and caption overlays',
      'Convert video formats (e.g. MP4, WebM, AV1, HEVC etc)',
      'Create adaptive streaming formats (e.g. HLS, DASH, etc)',
      'Convert audio formats (e.g. MP3, AAC, FLAC, OGG etc)',
      'Convert image formats (e.g. JPG, PNG, WebP, AVIF etc)',
      'Convert video to images (e.g. thumbnails)',
      'Convert video to audio (e.g. podcasts)',
      'Save up to 90% bandwidth with industry-leading compression',
    ],
    body: 'Ittybit provides a simple API for creating all the formats and sizes you need to support any device or browser. You can also save bandwidth, speed-up loading times, and eliminate buffering with industry-leading compression. Crop, rotate, resize and edit images and videos, and repurpose content by converting videos to images or audio formats.',
    image:
      'https://ittybit-app.ittybitcdn.com/med_450n2T14V7wDWry0/file_450n2T14V7wDWry0',
    alt: 'ittybit mascot robot in front of a screenshot of the transform screen',
    icon: 'transform',
    iconColor: 'text-brand-green',
    borderColor: 'border-brand-green',
    prefix: 'features',
    slug: `transform`,
    primary: true,
  },
  {
    title: 'Automation',
    headline: 'Automation',
    lede: 'Create highly customised workflows to automate your media management, or allow us to automagically optimise for quality, latency, and cost',
    body: 'Create highly customised workflows to automate your media management, or allow us to automagically optimise for quality, latency, and cost',
    image: 'https://ittybit-app.ittybitcdn.com/med_q3n432o8nv2x/original',
    alt: 'ittybit mascot robot in front of a screenshot of the automation screen',
    beta: true,
    icon: 'automation',
    iconColor: 'text-brand-yellow',
    borderColor: 'border-brand-yellow',
    prefix: 'features',
    slug: `automation`,
    primary: true,
  },
  {
    title: 'Deliver',
    headline: 'Deliver',
    lede: 'Improve page load speeds and give your users a smooth experience by delivering media from an edge network with over 250 locations',
    body: 'Improve page load speeds and give your users a smooth experience by delivering media from an edge network with over 250 locations',
    image: 'https://ittybit-app.ittybitcdn.com/med_ac2lhuyq0lmk/original',
    alt: 'ittybit mascot robot in front of a screenshot of the deliver screen',
    icon: 'cdn',
    iconColor: 'text-brand-blue',
    borderColor: 'border-brand-blue',
    prefix: 'features',
    slug: `delivery`,
    primary: true,
  },
  {
    title: 'Access Rules',
    headline: 'Access Rules',
    lede: 'Protect your media with upload conditions, encrypted storage, signed transforms, delivery access controls, and expiry dates',
    body: 'Protect your media with upload conditions, encrypted storage, signed transforms, delivery access controls, and expiry dates',
    image: 'https://ittybit-app.ittybitcdn.com/med_yaz69cwhfzbw/original',
    alt: 'ittybit mascot robot in front of a screenshot of the security screen',
    icon: 'security',
    iconColor: 'text-brand-orange',
    borderColor: 'border-brand-orange',
    prefix: 'features',
    slug: `access`,
    primary: true,
    alpha: true,
  },
  {
    title: 'Player',
    headline: 'Player',
    lede: 'Create and customise media players for your video and audio files, with support for playlists, chapters, and more',
    body: 'Create and customise media players for your video and audio files, with support for playlists, chapters, and more',
    icon: 'player',
    iconColor: 'text-brand-magenta',
    borderColor: 'border-brand-magenta',
    prefix: 'features',
    slug: `player`,
    primary: true,
    alpha: true,
  },
  {
    title: 'Analytics',
    headline: 'Analytics',
    lede: "Track usage and performance of your media with detailed analytics that don't require cookie banners or user tracking",
    body: "Track usage and performance of your media with detailed analytics that don't require cookie banners or user tracking",
    image: 'https://ittybit-app.ittybitcdn.com/med_ki589kb3w4ez/original',
    alt: 'ittybit mascot robot in front of a screenshot of the analytics screen',
    icon: 'stats',
    iconColor: 'text-brand-green',
    borderColor: 'border-brand-green',
    prefix: 'features',
    slug: `stats`,
    primary: true,
    alpha: true,
  },
];

export function getOrgMenuItems({ user, org_alias }) {
  if (!user) return [];

  let items = [
    {
      href: '/docs/api',
      text: 'API Docs',
      icon: 'terminal',
      secondary: true,
    },
    {
      href: `/orgs/${org_alias}`,
      text: 'Back to Org',
      icon: 'organisation',
      secondary: true,
    },
    {
      href: '/orgs',
      text: 'Dashboard',
      icon: 'dashboard',
      secondary: true,
    },
    {
      href: '/account',
      text: user.email || 'Account',
      icon: 'account',
      secondary: true,
    },
  ];

  const org = user.organisations.find(org => org.alias === org_alias);
  items.push(
    {
      href: `/orgs/${org_alias}/projects`,
      text: 'Projects',
      icon: 'project',
      iconColor: 'text-brand-magenta',
      projects: org.projects.map(project => ({
        href: `/${project.alias}`,
        text: project.name,
        icon: 'project',
        iconColor: 'text-brand-magenta',
      })),
      primary: true,
    },
    {
      href: `/orgs/${org_alias}/team`,
      text: 'Team',
      icon: 'team',
      iconColor: 'text-brand-yellow',
      primary: true,
    },
    {
      href: `/orgs/${org_alias}/billing`,
      text: 'Billing',
      icon: 'payment',
      iconColor: 'text-brand-green',
      primary: true,
    },
    {
      href: `/orgs/${org_alias}/settings`,
      text: 'Settings',
      icon: 'settings',
      iconColor: 'text-brand-blue',
      primary: true,
    },
  );

  return items;
}

export const orgMenuItems = [
  {
    title: 'Projects',
    icon: 'project',
    iconColor: 'text-brand-magenta',
    borderColor: 'border-brand-magenta',
    slug: `projects`,
    dashboardItem: false,
  },
  {
    title: 'Team',
    description: 'Invite and manage access for your team members',
    icon: 'team',
    iconColor: 'text-brand-yellow',
    borderColor: 'border-brand-yellow',
    slug: `team`,
    dashboardItem: true,
  },
  {
    title: 'Billing',
    description: 'View and manage your billing and payment information',
    icon: 'payment',
    iconColor: 'text-brand-blue',
    borderColor: 'border-brand-blue',
    slug: `billing`,
    dashboardItem: true,
  },
  {
    title: 'Settings',
    description:
      "Update your organisation's name, description, and other settings",
    icon: 'settings',
    iconColor: 'text-brand-purple',
    borderColor: 'border-brand-purple',
    slug: `settings`,
    dashboardItem: true,
    secondary: true,
  },
];

export function getProjectMenuItems({ user, project }) {
  if (!user) return [];
  const project_alias = project.alias;
  const org_alias = project.organisation.alias;
  let items = [
    {
      text: 'Media',
      icon: 'media',
      iconColor: 'text-product-media',
      href: `/${project_alias}/media`,
      primary: true,
      pages: [
        {
          id: 'videos',
          text: 'Videos',
          icon: 'video',
          iconColor: 'text-product-video',
          href: `/${project_alias}/media?kind=video`,
          params: `kind=video`,
        },
        {
          id: 'images',
          text: 'Images',
          icon: 'image',
          iconColor: 'text-product-image',
          href: `/${project_alias}/media?kind=image`,
          params: `kind=image`,
        },
        {
          id: 'audio',
          text: 'Audio',
          icon: 'audio',
          iconColor: 'text-product-audio',
          href: `/${project_alias}/media?kind=audio`,
          params: `kind=audio`,
        },
      ],
    },
    // {
    //   text: 'Analytics',
    //   icon: 'stats',
    //   iconColor: 'text-product-analytics',
    //   href: `/${project_alias}/analytics`,
    //   primary: true,
    // },
    {
      text: 'Tasks',
      icon: 'transform',
      iconColor: 'text-product-tasks',
      href: `/${project_alias}/tasks`,
      primary: true,
      pages: [
        {
          id: 'source',
          text: 'Sources',
          icon: 'sources',
          iconColor: 'text-product-source',
          href: `/${project_alias}/tasks?object=source`,
          params: `object=source`,
        },
        {
          id: 'intelligence',
          text: 'Intelligence',
          icon: 'intelligence',
          iconColor: 'text-product-intelligence',
          href: `/${project_alias}/tasks?object=intelligence`,
          params: `object=intelligence`,
        },
        {
          id: 'track',
          text: 'Tracks',
          icon: 'tracks',
          iconColor: 'text-product-track',
          href: `/${project_alias}/tasks?object=track`,
          params: `object=track`,
        },
      ],
    },
    {
      text: 'Automations',
      icon: 'automation',
      iconColor: 'text-product-automations',
      href: `/${project_alias}/automations`,
      primary: true,
    },
    // {
    //   text: 'Webhooks',
    //   icon: 'webhooks',
    //   iconColor: 'text-product-webhooks',
    //   href: `/${project_alias}/webhooks`,
    //   primary: true,
    // },
    // {
    //   text: 'Embeds',
    //   icon: 'player',
    //   iconColor: 'text-product-players',
    //   href: `/${project_alias}/embeds`,
    //   primary: true,
    // },
    // {
    //   text: 'Domains',
    //   icon: 'domains',
    //   iconColor: 'text-product-domains',
    //   href: `/${project_alias}/domains`,
    //   primary: true,
    // },
    // {
    //   text: 'Access',
    //   icon: 'security',
    //   iconColor: 'text-product-access',
    //   href: `/${project_alias}/access`,
    //   primary: true,
    // },
    // {
    //   text: 'Logs',
    //   icon: 'logs',
    //   iconColor: 'text-product-logs',
    //   href: `/${project_alias}/logs`,
    //   primary: true,
    // },
    {
      text: 'API Keys',
      icon: 'keys',
      // iconColor: 'text-product-keys',
      iconColor: 'text-brand-magenta',
      href: `/${project_alias}/keys`,
      primary: true,
    },
    // {
    //   title: 'Settings',
    //   icon: 'settings',
    //   iconColor: 'text-brand-purple',
    //   href: `/${project_alias}/settings`,
    //   secondary: true,
    // },
    {
      href: '/docs/api',
      text: 'API Docs',
      icon: 'terminal',
      secondary: true,
    },
    // {
    //   href: `/${project_alias}`,
    //   text: 'Back to Project',
    //   icon: 'project',
    //   secondary: true,
    // },
    {
      href: `/orgs/${org_alias}`,
      text: 'Back to Org',
      icon: 'organisation',
      secondary: true,
    },
    {
      href: '/orgs',
      text: 'Dashboard',
      icon: 'dashboard',
      secondary: true,
    },
    {
      href: '/account',
      text: user.email || 'Account',
      icon: 'account',
      secondary: true,
    },
  ];
  return items;
}

export const projectMenuItems = [
  {
    title: 'Media',
    description: 'Upload, view, and manage the items in your media library',
    icon: 'media',
    iconColor: 'text-product-media',
    borderColor: 'border-product-media',
    slug: `media`,
    dashboardItem: true,
    menuItem: true,
  },
  // NOTE: Moved files to an optional view inside of media view
  // {
  //   title: 'Files',
  //   description: "Get a more granular view of all your project's files",
  //   icon: 'files',
  //   iconColor: 'text-brand-purple',
  //   borderColor: 'border-brand-purple',
  //   slug: `files`,
  //   dashboardItem: true,
  // },
  //   menuItem: true
  // {
  //   title: 'Analytics',
  //   description: 'See views, trends, and other stats for your media files',
  //   icon: 'stats',
  //   iconColor: 'text-product-analytics',
  //   borderColor: 'border-product-analytics',
  //   slug: `analytics`,
  //   dashboardItem: true,
  //   menuItem: true,
  // },
  {
    title: 'Tasks',
    description: 'Transform and extract rich data from your media files',
    icon: 'transform',
    iconColor: 'text-product-tasks',
    borderColor: 'border-product-tasks',
    slug: `tasks`,
    dashboardItem: true,
    menuItem: true,
  },
  {
    title: 'Automations',
    description: 'Set up triggers and actions for uploaded and ingested media',
    icon: 'automation',
    iconColor: 'text-product-automations',
    borderColor: 'border-product-automations',
    slug: `automations`,
    dashboardItem: true,
    menuItem: true,
  },
  // {
  //   title: 'Webhooks',
  //   description: 'Add webhooks to get notified about events in your project',
  //   icon: 'webhooks',
  //   iconColor: 'text-product-webhooks',
  //   borderColor: 'border-product-webhooks',
  //   slug: `webhooks`,
  //   dashboardItem: true,
  //   menuItem: true,
  // },
  // {
  //   title: 'Players',
  //   description:
  //     'Create and manage media players for your video and audio files',
  //   icon: 'player',
  //   iconColor: 'text-product-players',
  //   borderColor: 'border-product-players',
  //   slug: `players`,
  //   dashboardItem: true,
  //   menuItem: true,
  // },
  // {
  //   title: 'Domains',
  //   description: 'Manage the custom domains and URLs used for your media',
  //   icon: 'domains',
  //   iconColor: 'text-product-domains',
  //   borderColor: 'border-product-domains',
  //   slug: `domains`,
  //   dashboardItem: true,
  //   menuItem: true,
  // },
  // {
  //   title: 'Access',
  //   description: 'Secure your media and set up default access rules',
  //   icon: 'security',
  //   iconColor: 'text-product-access',
  //   borderColor: 'border-product-access',
  //   slug: `access`,
  //   dashboardItem: true,
  //   menuItem: true,
  // },
  // {
  //   title: 'Logs',
  //   description: 'Examine recent logs to validate and debug your API calls',
  //   icon: 'logs',
  //   iconColor: 'text-product-logs',
  //   borderColor: 'border-product-logs',
  //   slug: `logs`,
  //   dashboardItem: true,
  //   menuItem: true,
  // },
  {
    title: 'API Keys',
    description: 'Create, view, and update the keys used for API operations',
    icon: 'keys',
    // iconColor: 'text-product-keys',
    iconColor: 'text-brand-magenta',
    // borderColor: 'border-product-keys',
    borderColor: 'border-brand-magenta',
    slug: `keys`,
    dashboardItem: true,
    menuItem: true,
  },
  {
    title: 'Settings',
    description: "Update your project's name, description, and other settings",
    icon: 'settings',
    iconColor: 'text-brand-purple',
    borderColor: 'border-brand-purple',
    slug: `settings`,
    dashboardItem: true,
    menuItem: true,
  },
];

// green
// blue
// magenta
// orange
// yellow
// purple

export const apiMenuItems = [
  {
    id: 'docs',
    title: 'API Docs',
    icon: 'terminal',
    iconColor: 'text-brand-green',
    slug: '',
    pages: [
      {
        id: 'docs',
        title: 'Essentials',
        icon: 'info',
        iconColor: 'text-brand-blue',
        slug: '',
        sections: [
          { id: 'introduction', title: 'Introduction' },
          { id: 'requests', title: 'Requests' },
          { id: 'responses', title: 'Responses' },
          { id: 'pagination', title: 'Pagination' },
          { id: 'authentication', title: 'Authentication' },
          { id: 'versioning', title: 'Versioning' },
          { id: 'status-codes', title: 'Status Codes' },
          { id: 'errors', title: 'Errors' },
          { id: 'rate-limits', title: 'Rate Limits' },
          { id: 'idempotency', title: 'Idempotency' },
        ],
      },
      // {
      //   id: 'quickstart',
      //   title: 'Quickstart',
      //   icon: 'quickstart',
      //   iconColor: 'text-brand-yellow',
      //   slug: 'quickstart',
      // },
      // {
      //   id: 'endpoints',
      //   title: 'Endpoints',
      //   icon: 'webhooks',
      //   iconColor: 'text-brand-orange',
      //   slug: 'endpoints',
      // },
      // {
      //   id: 'errors',
      //   title: 'Errors',
      //   icon: 'warning',
      //   iconColor: 'text-brand-magenta',
      //   slug: 'errors',
      // },
      // {
      //   id: 'libraries',
      //   title: 'Libraries',
      //   icon: 'code',
      //   iconColor: 'text-brand-blue',
      //   slug: 'libraries',
      // },
      // {
      //   id: 'guides',
      //   title: 'Guides',
      //   icon: 'docs',
      //   iconColor: 'text-brand-yellow',
      //   slug: 'guides',
      // },
    ],
  },
  {
    id: 'media',
    title: 'Media',
    icon: 'media',
    iconColor: 'text-product-media',
    slug: 'media',
    pages: [
      {
        id: 'media',
        title: 'Media Items',
        icon: 'sources',
        iconColor: 'text-brand-yellow',
        slug: 'media',
        sections: [
          { id: 'create-media-item', title: 'Create Media Item' },
          { id: 'list-media-items', title: 'List Media Items' },
          { id: 'view-media-item', title: 'View Media Item' },
          { id: 'update-media-item', title: 'Update Media Item' },
          { id: 'delete-media-item', title: 'Delete Media Item' },
        ],
      },
      {
        id: 'uploads',
        title: 'Create Upload',
        icon: 'upload',
        iconColor: 'text-brand-green',
        slug: 'uploads',
        sections: [
          { id: 'how-to-upload-files', title: 'How To Upload Files' },
          { id: 'a-simple-upload-method', title: 'Simple Uploads' },
          { id: 'b-resumable-upload-method', title: 'Resumable Uploads' },
          { id: '3-optional-save-media-details', title: 'Save Media Details' },
          { id: 'upload-conditions', title: 'Upload Conditions' },
        ],
      },
      // {
      //   id: 'sources',
      //   title: 'Sources',
      //   icon: 'source',
      //   iconColor: 'text-product-source',
      //   slug: 'sources',
      // },
      // {
      //   id: 'tracks',
      //   title: 'Tracks',
      //   icon: 'tracks',
      //   iconColor: 'text-product-track',
      //   slug: 'tracks',
      // },
      // {
      //   id: 'intelligence',
      //   title: 'Intelligence',
      //   icon: 'intelligence',
      //   iconColor: 'text-product-intelligence',
      //   slug: 'intelligence',
      // },
      // {
      //   id: 'videos',
      //   title: 'Videos',
      //   icon: 'video',
      //   iconColor: 'text-product-video',
      //   slug: 'videos',
      // },
      // {
      //   id: 'images',
      //   title: 'Images',
      //   icon: 'image',
      //   iconColor: 'text-product-image',
      //   slug: 'images',
      // },
      // {
      //   id: 'audio',
      //   title: 'Audio',
      //   icon: 'audio',
      //   iconColor: 'text-product-audio',
      //   slug: 'audio',
      // },
      // {
      //   id: 'folders',
      //   title: 'Folders',
      //   icon: 'folder',
      //   iconColor: 'text-brand-magenta',
      //   slug: 'folders',
      // },
      // {
      //   id: 'files',
      //   title: 'Files',
      //   icon: 'files',
      //   iconColor: 'text-brand-blue',
      //   slug: 'files',
      // },
      // {
      //   id: 'metadata',
      //   title: 'Metadata',
      //   icon: 'metadata',
      //   iconColor: 'text-brand-orange',
      //   slug: 'metadata',
      // },
    ],
  },
  {
    id: 'tasks',
    title: 'Tasks',
    icon: 'transform',
    iconColor: 'text-product-tasks',
    slug: 'tasks',
    pages: [
      {
        id: 'tasks',
        title: 'Task Objects',
        icon: 'tasks',
        iconColor: 'text-brand-pink',
        slug: 'tasks',
      },
      // {
      //   id: 'transforms',
      //   title: 'Create Source',
      //   icon: 'sources',
      //   iconColor: 'text-product-source',
      //   slug: 'tasks/sources',
      // },
      // {
      //   id: 'intelligence',
      //   title: 'Create Intelligence',
      //   icon: 'intelligence',
      //   iconColor: 'text-product-intelligence',
      //   slug: 'tasks/intelligence',
      // },
      // {
      //   id: 'tracks',
      //   title: 'Create Track',
      //   icon: 'tracks',
      //   iconColor: 'text-product-track',
      //   slug: 'tasks/tracks',
      // },
    ],
  },
  // {
  //   id: 'automations',
  //   title: 'Automations',
  //   icon: 'automation',
  //   iconColor: 'text-product-automations',
  //   slug: 'automations',
  //   pages: [
  //     {
  //       id: 'automations',
  //       title: 'Get Started',
  //       icon: 'quickstart',
  //       iconColor: 'text-brand-orange',
  //       slug: 'automations',
  //     },
  //     {
  //       id: 'workflows',
  //       title: 'Workflows',
  //       icon: 'workflows',
  //       iconColor: 'text-brand-green',
  //       slug: 'workflows',
  //     },
  //     {
  //       id: 'webhooks',
  //       title: 'Webhooks',
  //       icon: 'webhooks',
  //       iconColor: 'text-product-webhooks',
  //       slug: 'webhooks',
  //     },
  //   ],
  // },
  // {
  //   id: 'analytics',
  //   title: 'Analytics',
  //   icon: 'stats',
  //   iconColor: 'text-product-analytics',
  //   slug: 'analytics',
  //   pages: [],
  // },
  // {
  //   id: 'project',
  //   title: 'Project',
  //   icon: 'project',
  //   iconColor: 'text-brand-magenta',
  //   slug: 'project',
  //   pages: [
  //     {
  //       id: 'domains',
  //       title: 'Domains',
  //       icon: 'domains',
  //       iconColor: 'text-brand-blue',
  //       slug: 'domains',
  //     },
  //     {
  //       id: 'players',
  //       title: 'Players',
  //       icon: 'player',
  //       iconColor: 'text-brand-magenta',
  //       slug: 'players',
  //     },
  //     {
  //       id: 'access',
  //       title: 'Access',
  //       icon: 'security',
  //       iconColor: 'text-brand-orange',
  //       slug: 'access',
  //     },
  //     {
  //       id: 'logs',
  //       title: 'Logs',
  //       icon: 'logs',
  //       iconColor: 'text-brand-green',
  //       slug: 'logs',
  //     },
  //     {
  //       id: 'keys',
  //       title: 'API Keys',
  //       icon: 'keys',
  //       iconColor: 'text-brand-yellow',
  //       slug: 'keys',
  //     },
  //   ],
  // },
  // {
  //   id: 'orgs',
  //   title: 'Orgs',
  //   icon: 'organisation',
  //   iconColor: 'text-brand-purple',
  //   slug: 'orgs',
  //   pages: [
  //     {
  //       id: 'projects',
  //       title: 'Projects',
  //       icon: 'project',
  //       iconColor: 'text-brand-magenta',
  //       slug: 'projects',
  //       sections: [
  //         { id: 'list-projects', title: 'List Projects' },
  //         { id: 'create-project', title: 'Create Project' },
  //         { id: 'view-project', title: 'View Project' },
  //         { id: 'update-project', title: 'Update Project' },
  //         { id: 'delete-project', title: 'Delete Project' },
  //       ],
  //     },
  //     {
  //       id: 'team',
  //       title: 'Team',
  //       icon: 'team',
  //       iconColor: 'text-brand-yellow',
  //       slug: 'team',
  //     },
  //     {
  //       id: 'billing',
  //       title: 'Billing',
  //       icon: 'payment',
  //       iconColor: 'text-brand-green',
  //       slug: 'billing',
  //     },
  //   ],
  // },
];

let mobileItems = [];
apiMenuItems.forEach(item => {
  if (item.slug) {
    mobileItems.push({
      id: item.id,
      title: item.title,
      icon: item.icon,
      iconColor: item.iconColor,
      slug: item.slug,
    });
  }
  item.pages.forEach(page => {
    if (Boolean(mobileItems.find(i => i.id === page.id))) {
      return;
    }
    mobileItems.push({
      id: page.id,
      title: page.title,
      icon: page.icon,
      iconColor: page.iconColor,
      slug: page.slug,
      sections: page.sections || null,
    });
  });
});
export const apiMobileMenuItems = mobileItems;
