'use client';

import { marketingMenuItems } from '@/components/navigation';
import { usePathname } from 'next/navigation';
import { Icon, Link } from '@/components/utilities';

function FeatureLink({ href, icon, iconColor, text, pathname }) {
  return (
    <Link
      href={href}
      className={`flex items-center  ${
        pathname.includes(href) ? 'text-1' : 'text-3 hover:text-1'
      }`}
      title={text}>
      <Icon
        icon={icon}
        className={`w-5 h-5 xl:w-6 xl:h-6 mr-3 xl:mr-4 ${iconColor}`}
      />
      <span className="hidden lg:block max-w-[15rem] 2xl:max-w-[20rem] whitespace-nowrap text-ellipsis overflow-hidden text-sm xl:text-base leading-6 font-code">
        {text}
      </span>
    </Link>
  );
}

export default function FeaturesMenu() {
  const pathname = usePathname();
  return (
    <ul className={`flex flex-col items-stretch justify-start py-8`}>
      {marketingMenuItems?.map(item => (
        <li key={item.slug} className="py-2">
          <FeatureLink
            href={`/features/${item.slug}`}
            icon={item.icon}
            iconColor={item.iconColor}
            text={item.title}
            pathname={pathname}
          />
        </li>
      ))}
    </ul>
  );
}
