import { Link, Picture } from '@/components/utilities';

export default function NavigationLogo({
  href = '/',
  className = 'h-6 w-24 lg:h-8 lg:w-32 lg:my-1',
}) {
  return (
    <Link
      block
      href={href}
      className="px-4 md:px-8 py-3 opacity-80 hover:opacity-100 flex-shrink-0">
      <Picture
        src="https://ittybit-app.ittybitcdn.com/med_rbcpu5q6hdrp/original"
        width="160"
        height="40"
        sizes="96px"
        className={`relative ${className}`}
        alt="ittybit"
        rendering="pixelated"
        priority
      />
    </Link>
  );
}
