'use client';

import { useState } from 'react';
import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from '@headlessui/react';

import { Icon, Link } from '@/components/utilities';
import { usePathname } from 'next/navigation';
import { getProjectMenuItems } from '.';

export default function ProjectMobileMenu({ user, project }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const pathname = usePathname();

  function toggleMenu(e) {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  }

  if (!user) {
    return null;
  }

  let items = getProjectMenuItems({ user, project });

  return (
    <>
      <button
        type="button"
        className="group p-2 lg:hidden"
        onClick={toggleMenu}>
        <span className="sr-only">Open menu</span>
        <Icon
          icon="menu"
          className="h-8 w-8 text-white text-opacity-60 group-hover:text-opacity-100"
          aria-hidden="true"
        />
      </button>

      {/* Vertical version for narrow screens */}
      <Transition show={menuOpen}>
        <Dialog className="relative z-50 lg:hidden" onClose={toggleMenu}>
          <TransitionChild
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-dark-100/80" />
          </TransitionChild>

          <div className="fixed inset-0 flex">
            <TransitionChild
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full">
              <DialogPanel className="relative mr-16 flex w-full max-w-xs flex-1">
                <TransitionChild
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0">
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-4">
                    <button
                      type="button"
                      className="group -m-2 p-2"
                      onClick={toggleMenu}>
                      <span className="sr-only">Close sidebar</span>
                      <Icon
                        icon="close"
                        className="h-6 w-6 text-white text-opacity-60 group-hover:text-opacity-100"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </TransitionChild>

                <div className="lg:hidden flex grow flex-col items-stretch justify-start h-full overflow-auto bg-dark-200 py-4">
                  <ul className="pb-8 w-full">
                    <li className="pb-0">
                      <Link
                        href={`/${project.alias}`}
                        className={`flex items-center px-4 md:px-6 py-2 md:py-3 ${
                          pathname === `/${project.alias}`
                            ? 'text-1'
                            : 'text-2 hover:text-1'
                        }`}>
                        <span className="max-w-[15rem] 2xl:max-w-[20rem] whitespace-nowrap text-ellipsis overflow-hidden text-base md:text-lg leading-6 font-code">
                          {project.name}
                        </span>
                      </Link>
                    </li>
                    {items
                      ?.filter(i => i.primary)
                      .map(({ href, text, icon, iconColor }) => {
                        const active = Boolean(pathname.includes(href));
                        return (
                          <li key={href} onClick={() => setMenuOpen(false)}>
                            <Link
                              href={href}
                              className={`flex items-center px-4 md:px-6 py-2 ${
                                active ? 'text-1' : 'text-3 hover:text-1'
                              }`}
                              title={text}>
                              <Icon
                                icon={icon}
                                className={`w-6 h-6 mr-4 md:mr-6 ${iconColor}`}
                              />
                              <span className="max-w-[15rem] whitespace-nowrap text-ellipsis overflow-hidden text-sm md:text-base leading-6 font-code">
                                {text}
                              </span>
                            </Link>
                          </li>
                        );
                      })}
                  </ul>
                  <ul className="mt-auto w-full">
                    {items
                      ?.filter(i => i.secondary)
                      .map(
                        ({
                          href,
                          text,
                          icon,
                          iconColor = 'text-brand-purple',
                        }) => (
                          <li key={href} onClick={() => setMenuOpen(false)}>
                            <Link
                              href={href}
                              className={`flex items-center px-4 md:px-6 py-2 ${
                                pathname === href
                                  ? 'text-1'
                                  : 'text-3 hover:text-1'
                              }`}
                              title={text}>
                              <Icon
                                icon={icon}
                                className={`w-5 h-5 mr-5 md:mr-7 ${iconColor}`}
                              />
                              <span className="max-w-[15rem] whitespace-nowrap text-ellipsis overflow-hidden text-xs md:text-sm leading-6 font-code">
                                {text}
                              </span>
                            </Link>
                          </li>
                        ),
                      )}
                  </ul>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
