'use client';

import { useParams, usePathname } from 'next/navigation';
import { Icon, Link } from '@/components/utilities';

const OrgBreadcrumbs = ({ org_alias }) => {
  return (
    <nav className="flex items-center font-code text-brand-300 text-sm">
      <Icon icon="chevron_right" className="hidden md:block w-5 h-5" />
      <Link
        href={`/${org_alias}`}
        className="flex items-center space-x-2 lg:space-x-3 text-white text-opacity-60 hover:text-opacity-100 px-2 lg:px-3 py-2"
        title="back to organisation">
        <Icon icon="organisation" className="text-brand-300 w-5 h-5" />
        <span>{org_alias}</span>
      </Link>
    </nav>
  );
};

const ProjectBreadcrumbs = ({ org_alias, project_alias }) => {
  return (
    <nav className="flex items-center font-code text-brand-300 text-sm">
      <Icon icon="chevron_right" className="hidden md:block w-5 h-5" />
      <Link
        href={`/orgs/${org_alias}`}
        className="hidden md:flex items-center space-x-2 lg:space-x-3 text-white text-opacity-60 hover:text-opacity-100 px-2 lg:px-3 py-2"
        title="back to organisation">
        <Icon icon="organisation" className="text-brand-300 w-5 h-5" />
        <span>{org_alias}</span>
      </Link>
      <Icon icon="chevron_right" className="hidden md:block w-5 h-5" />
      <Link
        href={`/${project_alias}`}
        className="flex items-center space-x-2 lg:space-x-3 text-white text-opacity-60 hover:text-opacity-100 px-2 lg:px-3 py-2"
        title="back to project">
        <Icon icon="project" className="text-brand-300 w-5 h-5" />
        <span>{project_alias}</span>
      </Link>
    </nav>
  );
};

const Breadcrumbs = ({ className = '' }) => {
  const { org_alias, project_alias } = useParams();
  const pathname = usePathname();
  let parts = pathname?.split('/').slice(1) || [];
  let lastPart = parts[parts.length - 1];
  let href = '';

  if (project_alias && lastPart !== project_alias) {
    return (
      <ProjectBreadcrumbs org_alias={org_alias} project_alias={project_alias} />
    );
  }

  if (org_alias) {
    return <OrgBreadcrumbs org_alias={org_alias} />;
  }

  if (lastPart === 'orgs' || lastPart === 'account') {
    return null;
  }

  return (
    <nav className={`${className}`}>
      <ul className={`flex items-center font-code text-brand-300 text-sm`}>
        {parts?.map((part, index) => {
          href += '/' + part;
          return (
            <li key={index} className="flex items-center">
              <Icon
                icon="chevron_right"
                className={`w-5 h-5
              ${
                index === parts.length - 2
                  ? `block`
                  : index === parts.length - 3
                  ? `hidden sm:block`
                  : `hidden lg:block`
              }
            `}
              />
              <Link
                href={href}
                className={`${
                  index === parts.length - 2
                    ? `block`
                    : index === parts.length - 3
                    ? `hidden sm:block`
                    : `hidden lg:block`
                } text-white text-opacity-60 hover:text-opacity-100 px-2 lg:px-3 py-2 whitespace-nowrap text-ellipsis`}>
                {part}
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default Breadcrumbs;
