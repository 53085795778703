'use client';

import { usePathname } from 'next/navigation';
import { Icon, Link } from '@/components/utilities';

export default function NavigationLink({ href, icon, text }) {
  const pathname = usePathname();
  return (
    <Link
      href={href}
      className={`flex items-center  ${
        pathname.includes(href) ? 'text-1' : 'text-3 hover:text-1'
      }`}
      title={text}>
      <span className="hidden lg:block max-w-[15rem] whitespace-nowrap text-ellipsis overflow-hidden text-sm leading-6 font-code">
        {text}
      </span>
      <Icon icon={icon} className="w-5 h-5 ml-2 md:ml-3 text-brand-purple" />
    </Link>
  );
}
